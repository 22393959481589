import { initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "gm",

  resources: {
    en: {
      translation: {
        qualified_results: "Over 430 qualified results",
        Transform_Heading: "Transform your ❤️ health with us",
        Personalized: "Personalized nutrition coaching",
        Welcome:
          "Welcome to KI-Nutritem – your expert for personalized nutrition and health optimization.",
        Certified:
          "Our certified nutritionists provide you with customized plans and ongoing support on your weight loss journey. Using our advanced LLM model aligned with Mediterranean Diet, Hormone Balance, Microbiome Optimization and Fitness, we deliver precise, evidence-based recommendations. Our AI-powered application analyzes your individual needs and creates personalized nutritional strategies to take your health to a new level. Start your transformation today and experience the precision and efficiency of data-driven nutrition coaching.",
        lets_go: "let's go",
        choose_individual: "Choose individual program",
        How_it_works: "How it works",
        get_started:
          "Get started easily and effectively on the path to better health and nutrition with our step-by-step instructions. We make it easy to get started and help you quickly navigate our platform and use the resources you need to achieve your health goals. This is how it works:",
        here_work: "Here's how it works",
        Features: "Our focus - your benefits",
        wellness:
          "Welcome to the features section of Nutritionist – your one-stop partner for personalized nutrition and wellness.",
        personalized: "Personalized nutrition plans",
        personalized_p:
          "Experience tailored nutritional strategies optimized by our advanced AI technology. Our application only analyzes anonymized data such as your health, activity level and dietary habits and does not collect any personal information. Get precise recommendations and adapt your nutrition plan individually. Through continuous updates and intelligent learning processes, your plan is regularly optimized so that you always make the best progress - in line with the highest data protection standards.",
        guidance: "Guidance from certified nutritionists",
        guidance_p:
          "Our team of experienced and certified nutritionists will provide you with professional advice and support throughout your entire journey. We'll answer your questions, address your concerns, and keep you motivated as you pursue your goals.",
        food: "Food tracking and analysis",
        food_p:
          "Easily track your food intake with our easy-to-use app. Our nutritionists analyze your diet and eating habits to gain insights, help you identify opportunities for improvement and provide personalized recommendations.",
        meal: "Meal planning and recipes",
        meal_p:
          "Access an extensive collection of delicious and healthy recipes tailored specifically to your dietary needs. Our advanced AI technology helps you do this by optimizing recipes and meal plans based on your preferences and goals. Additionally, our nutritionists create personalized meal plans complemented by AI-driven recommendations to help you stay on track and enjoy nutritious meals. This means you benefit from an intelligent combination of expert knowledge and state-of-the-art technology.",
        lifestyle: "Lifestyle and behavior coaching",
        lifestyle_p:
          "Achieving lasting results requires more than just a diet plan. Our nutritionists will work with you to develop healthy habits, address emotional eating, and provide strategies for overcoming obstacles along the way.",
        workshops: "Nutritional education and workshops",
        workshops_p:
          "Expand your nutrition knowledge with informative articles and interactive workshops delivered through our AI-powered app. The intelligent platform provides you with tailored learning content and resources based on your individual needs. Our app helps you make informed decisions and gives you the tools you need to achieve long-term success and healthy habits.",
        blogs_h: "Our blogs",
        blogs_p:
          "Our blog is a treasure trove of informative and engaging articles written by our team of nutritionists, dietitians and wellness experts. This is what you can expect from our blog.",
        read_less: "Read Less",
        read_more: "Read More",
        practice_h: "More from practice",
        practice_p: "Be inspired by the success stories of our users!",
        feedback_p:
          "Many have already shared their progress with our app. You too can share your experiences to motivate others and support the further development of our technology. Your feedback helps us continually improve our app and expand scientific knowledge.",
        choose_gender: "Choose Your Gender?",
        select_goal: "Select Your Goal",
        select_age: "Select Your Age Group",
        select_age_p:
          "Slide to Your Age - Because every age has its own wisdom",
        next_btn: "Next",
        select_height: "Select Your Height",
        select_height_p:
          "Slide to Your Height – Because every body size has its own balance",
        select_body_type: "Select Your Body Type",
        slim: "Slim",
        average: "Average",
        heavy: "Heavy",
        lose_weight: "Lose Weight",
        gain_muscle: "Gain Muscle Mass",
        get_shredded: "Get Shredded",
        specific_goal: "Select Your Specific Goal",
        slim_body: "Slim Body",
        slim_shredded_body: "Slim Shredded Body",
        athlete: "Athlete",
        hero: "Hero",
        bodybuilder: "Bodybuilder",
        beach_body: "Beach Body",
        workout_body: "Workout Body",
        cross_fit_body: "Cross-fit Body",
        back: "Back",
        stick: "Do you stick to one of these diets?",
        vegetarian: "Vegetarian",
        vegan: "Vegan",
        keto: "Keto",
        mediterranean: "Mediterranean",
        none: "None of the above",
        sugary_food: "How often do you eat or drink sugary foods or drinks?",
        not_often: "Not often. I don't like sweets that much.",
        three_times: "3-5 times per week",
        every_day: "Almost every day",
        water_intake: "How much water do you drink every day?",
        coffee: "Coffee or tea only",
        two_glasses: "Less than 2 glasses",
        six_glasses: "2-6 glasses",
        seven: "7-10 glasses",
        ten_glasses: "More than 10 glasses",
        medications:
          "Which of the following medications are you currently taking or have you taken in the last three months?",
        current_weight:
          "What is your current weight and how much would you like to weight?",
        select_unit: "Select unit",
        current_weight_lb: "Current weight",
        target_weight: "Target weight",
        fitness_level: "What is your fitness level?",
        fitness_level_p:
          "Choose on a scale from 1 to 10, where 1 stands for beginner and 10 for a very advanced fitness level. Use the slider to determine your current level.",
        fat_level: "Choose Your Body Fat Level",
        meal_plan: "Let Us Create a Meal Plan Based on Your Preferences",
        veggies: "Veggies",
        grains: "Grains",
        ingredients: "Ingredients",
        meat_fish: "Meat & Fish",
        fruits: "Fruits",
        allergic_h: "Select What Are You Allergic To",
        support_text: "Do you need help...",
        weight_loss: "for the desired weight loss?",
        digestive: "for digestive problems and intolerances?",
        performance: "in optimizing your physical and mental performance?",
        stabilize: "stabilize your immune and hormonal system?",
        evaluation:
          "Take the first step now and complete the nutricheck. You will receive your free evaluation in 5-10 minutes.",
        carefully:
          "Read the following questions carefully and answer them with a yes or no.",
        lets_get_started: "Let's get started",
        Broccoli: "Broccoli",
        Cauliflower: "Cauliflower",
        Onion: "Onion",
        Bell_Pepper: "Bell Pepper",
        Eggplant: "Eggplant",
        Cabbage: "Cabbage",
        Asparagus: "Asparagus",
        Spinach: "Spinach",
        Cucumber: "Cucumber",
        Tomato: "Tomato",
        Rice: "Rice",
        Quinoa: "Quinoa",
        Couscous: "Couscous",
        Buckwheat: "Buckwheat",
        Millet_Groats: "Millet Groats",
        Amaranth: "Amaranth",
        Semoline: "Semoline",
        Cornmeal: "Cornmeal",
        Bulgur: "Bulgur",
        Avocado: "Avocado",
        Beans: "Beans",
        Eggs: "Eggs",
        Mushrooms: "Mushrooms",
        Cottage_cheese: "Cottage cheese",
        Milk: "Milk",
        Tofu: "Tofu",
        Hummus: "Hummus",
        Plant_milk: "Plant milk",
        Protein_powder: "Protein powder",
        Vegan_protein_powder: "Vegan protein powder",
        Turkey: "Turkey",
        Beef: "Beef",
        Chicken: "Chicken",
        Seafood: "Seafood",
        Pork: "Pork",
        Fish: "Fish",
        Citrus: "Citrus",
        Apple: "Apple",
        Pear: "Pear",
        Kiwi: "Kiwi",
        Bananas: "Bananas",
        Persimmon: "Persimmon",
        Peach: "Peach",
        Berries: "Berries",
        Grape: "Grape",
        Pomegranate: "Pomegranate",
        antibiotics: "Antibiotics < 3 months",
        antibiotics_three: "Antibiotics > 3 months",
        Chemotherapy: "Chemotherapy",
        proton_pump: "Proton pump inhibitors (PPI)",
        laxatives: "Laxatives (laxatives)",
        immunsuppressiva: "Immunosuppressants",
        home: "Home",
        offers: "Offers",
        references: "References",
        about_us: "About us",
        point_one: "Step: Fill out the comprehensive questionnaire truthfully.",
        point_two:
          "Step: Receive the AI-generated analysis report with your individual recommendations.",
        point_three:
          "Step: Implement the simple recommendations that are suitable for everyday use. If you can contact us for further advice and laboratory analyses. feel free to contact us.",
        two_glasses_sec: "up to 0.5 l / 17 oz",
        six_glasses_sec: "0.5-1.5 l / 17-50 oz",
        seven_sec: "1.5-2.5 l / 50-85 oz",
        ten_glasses_sec: "more than 2.5 l / 85 oz",
        how_many_fruits_vegetables:
          "How many fruits or vegetables do you eat per day?",
        more_than_3: "More than 3",
        how_often_consume_starch:
          "How often per day do you consume starch sources such as potatoes, rice, bread, pasta, cereal flakes, etc.?",
        how_many_servings_wholegrain:
          "How many servings of the starch suppliers consist of wholemeal flour or the whole grain?",
        how_many_portions_nuts_seeds_kernels_per_week:
          "How many portions of nuts, seeds and kernels do you eat per week?",
        how_many_hours_sleep_per_night:
          "How many hours do you sleep on average per night?",
        portion: "1 portion = 1 handful",
        select_work_percentage: "What percentage do you work?",
      },
    },

    gm: {
      translation: {
        qualified_results: "Über 430 qualifizierte Ergebnisse",
        Transform_Heading: "Verwandele deine ❤️ Gesundheit mit",
        Personalized: "Personalisierte KI-Ernährungsberatung",
        Welcome:
          "Wissenschaftlich basierend, mit jahrelanger Erfahrung und gesundem Menschenverstand ergänzt!",
        Certified:
          "Unser Team aus Ernährungsberater/innen und Bewegungsspezialisten bieten dir maßgeschneiderte Pläne und kontinuierliche Unterstützung auf deinem Weg. Durch den Einsatz unserer KI-Technologie, welche auf die traditionelle mediterrane Ernährung, Hormonbalance, Mikrobiom-Optimierung und Fitness abgestimmt ist, liefern wir präzise, evidenzbasierte Empfehlungen, welche auf dich abgestimmt sind. Unsere KI-gestützte Applikation analysiert deine individuellen Bedürfnisse und erstellt personalisierte Ernährungsstrategien, um deine Gesundheit auf ein neues Level zu heben. Starte noch heute mit deinem nutricheck!",
        lets_go: "Los gehts",
        choose_individual: "Individuelles Programm wählen",
        How_it_works: "Wie funktioniert es?",
        get_started:
          "Starte einfach und schnell mit unserer 3-Schritt-Anleitung zu deinem persönlichen Analysebericht. Wir machen dir den Einstieg leicht und helfen dir, dich schnell auf unserer Plattform zurechtzufinden und die Ressourcen zu nutzen, die du benötigst, um deine Gesundheitsziele zu erreichen. So funktioniert es",
        here_work: "So funktioniert's",
        Features: "Unsere Schwerpunkte – deine Vorteile",
        wellness:
          "Willkommen im Feature-Bereich von Nutritionist – Ihrem umfassenden Partner für personalisierte Ernährung und Wellness.",
        personalized: "Personalisierte Ernährungspläne",
        personalized_p:
          "Erhalte maßgeschneiderte Ernährungsstrategien, die durch unsere fortschrittliche KI-Technologie optimiert werden. Unsere Anwendung analysiert ausschließlich anonymisierte Daten wie deinen Gesundheitszustand, dein Aktivitätsniveau und deine Ernährungsgewohnheiten und erhebt keine persönlichen Informationen. Lass dir präzise Empfehlungen erstellen und passe deinen Ernährungsplan individuell an. Durch kontinuierliche Updates und intelligente Lernprozesse wird dein Plan regelmäßig optimiert, damit du stets den besten Fortschritt erzielst – ganz im Einklang mit höchsten Datenschutzstandards.",
        guidance: "Unterstützung von den Profis",
        guidance_p:
          "Unser Team besteht ausschliesslich aus erfahrenen und studierten Ernährungsberater/innen BSc FH und steht dir während deiner gesamten Reise mit professioneller Beratung und Unterstützung zur Seite. Dabei geht es weit mehr als nur um Ernährungspläne: Wir berücksichtigen deine ndividuelle Ausgangslage und holen dich dort ab wo du aktuell stehst. Wir werden mit dir zusammen an deinen Gewohnheiten arbeiten, bei Bedarf Strategien gegen emotionales Essen entwickeln und Stolpersteine aus dem Weg räumen.",
        food: "Lebensmittel-Tracking und Rezeptdatenbank mittels App",
        food_p:
          "Verfolge schnell und einfach dein Essen mit unserer benutzerfreundlichen App. Unsere Ernährungsberaterinnen analysieren deine Ernährung und Essgewohnheiten und helfen dir, Verbesserungsmöglichkeiten zu erkennen und umzusetzen. Greife auf eine umfangreiche Sammlung köstlicher und gesunder Rezepte zu, die speziell auf deine Ernährungsbedürfnisse abgestimmt sind. Unsere fortschrittliche KI-Technologie unterstützt dich dabei, indem sie Rezepte und Speisepläne basierend auf deinen Vorlieben und Zielen optimiert.",
        meal: "Essensplanung und Rezepte",
        meal_p:
          "Greife auf eine umfangreiche Sammlung köstlicher und gesunder Rezepte zu, die speziell auf Deine Ernährungsbedürfnisse abgestimmt sind. Unsere fortschrittliche KI-Technologie unterstützt Dich dabei, indem sie Rezepte und Speisepläne basierend auf Deinen Vorlieben und Zielen optimiert. Zusätzlich erstellen unsere Ernährungsberater personalisierte Speisepläne, die durch KI-gesteuerte Empfehlungen ergänzt werden, um Dir zu helfen, den Überblick zu behalten und nahrhafte Mahlzeiten zu genießen. So profitierst Du von einer intelligenten Kombination aus Expertenwissen und modernster Technologie.",
        lifestyle: "Lebensstil- und Verhaltenscoaching",
        lifestyle_p:
          "Um nachhaltige Ergebnisse zu erzielen, ist mehr als nur ein Diätplan erforderlich. Unsere Ernährungsberater werden mit Dir zusammenarbeiten, um gesunde Gewohnheiten zu entwickeln, emotionales Essen anzugehen und Strategien zur Überwindung von Hindernissen auf dem Weg bereitzustellen.",
        workshops: "Ernährungswissen und Workshops",
        workshops_p:
          "Erweitere dein Ernährungswissen mit informativen Artikeln und interaktiven Workshops, die durch unsere KI-gestützte App bereitgestellt werden. Die intelligente Plattform liefert dir maßgeschneiderte Lerninhalte und Ressourcen, die auf deinen individuellen Bedürfnissen basieren. Unsere App unterstützt dich dabei, fundierte Entscheidungen zu treffen und bietet dir die Werkzeuge, die du benötigst, um langfristigen Erfolg undgesunde Gewohnheiten umzusetzen.",
        blogs_h: "Unser Blog",
        blogs_p:
          "Unser Blog ist eine Fundgrube informativer und ansprechender Artikel, die von unserem Team verfasst wurden.",
        read_less: "Weniger...",
        read_more: "Mehr...",
        practice_h: "Mehr aus der Praxis",
        practice_p:
          "Lass Dich von den Erfolgsgeschichten unserer Nutzer inspirieren!",
        feedback_p:
          "Viele haben bereits ihre Fortschritte mit unserer App geteilt. Teile auch Du Deine Erfahrungen, um andere zu motivieren und die Weiterentwicklung unserer Technologie zu unterstützen. Dein Feedback trägt dazu bei, unsere App kontinuierlich zu verbessern und wissenschaftliche Erkenntnisse zu erweitern.",
        choose_gender: "Wähle dein Geschlecht ?",
        select_age: "Gebe deine Altersstufe ein",
        select_age_p:
          "Slide to Your Age – Weil jedes Alter seine eigene Weisheit hat",
        next_btn: "Weiter",
        select_goal: "Wähle dein Ziel",
        select_height: "Stelle deine Körpergröße ein",
        select_height_p:
          "Slide to Your Height - Weil jede Körpergröße ihre eigene Balance hat",
        select_body_type: "Wähle dein Körpertyp",
        slim: "Dünn",
        average: "Durchschnittlich",
        heavy: "Schwer",
        lose_weight: "Abnehmen",
        gain_muscle: "Muskeln aufbauen",
        get_shredded: "Mehr Ausdauer",
        specific_goal: "Wähle dein spezifisches Ziel",
        slim_body: "Schlank",
        slim_shredded_body: "Durchtrainiert",
        athlete: "Athlet",
        hero: "Held",
        bodybuilder: "Bodybuilder",
        beach_body: "Strandfigur",
        workout_body: "Durchtrainierter Körper",
        cross_fit_body: "Cross-fit Körper",
        back: "Zurück",
        stick: "Hältst Du Dich an eine dieser Diäten?",
        vegetarian: "vegetarisch",
        vegan: "Vegan",
        keto: "Keto",
        mediterranean: "Mittelmeer",
        none: "Keiner der oben genannten Punkte",
        sugary_food:
          "Wie oft isst oder trinkst Du zuckerhaltige Lebensmittel oder Getränke?",
        not_often: "Nicht oft. Ich stehe nicht so auf Süßes.",
        three_times: "3-5 Mal pro Woche",
        every_day: "Fast jeden Tag",
        water_intake: "Wie viel Wasser trinkst Du täglich?",
        coffee: "Nur Kaffee oder Tee",
        two_glasses: "Weniger als 2 Gläser",
        six_glasses: "2-6 Gläser",
        seven: "7-10 Gläser",
        ten_glasses: "Mehr als 10 Gläser",
        medications:
          "Welche der folgenden Medikamente nimmst Du derzeit ein oder hast Du in den letzten drei Monaten eingenommen?",
        current_weight:
          "Wie ist Dein aktuelles Gewicht und was ist dein Zielgewicht?",
        select_unit: "Einheit auswählen",
        current_weight_lb: "Aktuelles Gewicht",
        target_weight: "Zielgewicht",
        fitness_level: "Wie ist Dein Fitnesslevel?",
        fitness_level_p:
          "Wähle auf einer Skala von 1 bis 10, wobei 1 für Anfänger steht und 10 für ein sehr fortgeschrittenes Fitnessniveau. Nutze den Schieberegler, um Deinen aktuellen Stand zu bestimmen.",
        fat_level: "Wähle dein Körperfettanteil",
        meal_plan: "Lass uns einen Essensplan nach deinen Vorlieben erstellen.",
        veggies: "Gemüsesorten",
        grains: "Getreidearten",
        ingredients: "Inhaltsstoffe",
        meat_fish: "Fleisch & Fisch",
        fruits: "Früchte",
        allergic_h: "Wähle aus, gegen was du allergisch bist",
        support_text: "Brauchst du Unterstützung…",
        weight_loss: "bei einer gewünschten Gewichtsabnahme?",
        digestive: "bei Verdauungsproblemen und Unverträglichkeiten?",
        performance:
          "bei der Optimierung deiner physischen und mentalen Leistungsfähigkeit?",
        stabilize: "beim Stabilisieren deines Immun- und Hormonsystems?",
        evaluation:
          "Mache jetzt den ersten Schritt und absolviere den nutricheck. Du bekommst deine kostenlose Auswertung in 5-10 Minuten.",
        carefully:
          "Lese die folgenden Fragen aufmerksam durch und beantworte diese mit einem Ja oder Nein.",
        lets_get_started: "Lass uns beginnen",
        Broccoli: "Brokkoli",
        Cauliflower: "Blumenkohl",
        Onion: "Zwiebel",
        Bell_Pepper: "Paprika",
        Eggplant: "Aubergine",
        Cabbage: "Kohl",
        Asparagus: "Spargel",
        Spinach: "Spinat",
        Cucumber: "Gurke",
        Tomato: "Tomate",
        Rice: "Reis",
        Quinoa: "Quinoa",
        Couscous: "Couscous",
        Buckwheat: "Buchweizen",
        Millet_Groats: "Hirse",
        Amaranth: "Amaranth",
        Semoline: "Grieß",
        Cornmeal: "Maismehl",
        Bulgur: "Bulgur",
        Avocado: "Avocado",
        Beans: "Bohnen",
        Eggs: "Eier",
        Mushrooms: "Pilze",
        Cottage_cheese: "Hüttenkäse",
        Milk: "Milch",
        Tofu: "Tofu",
        Hummus: "Hummus",
        Plant_milk: "Pflanzenmilch",
        Protein_powder: "Eiweißpulver",
        Vegan_protein_powder: "Veganer Eiweißpulver",
        Turkey: "Pute",
        Beef: "Rindfleisch",
        Chicken: "Hähnchen",
        Seafood: "Meeresfrüchte",
        Pork: "Schweinefleisch",
        Fish: "Fisch",
        Citrus: "Zitrusfrüchte",
        Apple: "Apfel",
        Pear: "Birne",
        Kiwi: "Kiwi",
        Bananas: "Bananen",
        Persimmon: "Kaki",
        Peach: "Pfirsich",
        Berries: "Beeren",
        Grape: "Traube",
        Pomegranate: "Granatapfel",
        antibiotics: "Antibiotika < 3 Monate",
        antibiotics_three: "Antibiotika > 3 Monate",
        Chemotherapy: "Chemotherapie",
        proton_pump: "Protonenpumpenhemmer (PPI)",
        laxatives: "Laxantien (Abführmittel)",
        immunsuppressiva: "Immunsuppressiva",
        home: "Home",
        offers: "Angebote",
        references: "Referenzen",
        about_us: "Über uns",
        point_one:
          "Schritt: Fülle den umfassenden Fragekatalog wahrheitsgetreu aus.",
        point_two:
          "Schritt: Sie erhalten den von der KI erstellten Analysebericht mit Ihren individuellen Empfehlungen.",
        point_three:
          "Schritt: Setze die einfachen und alltagstauglichen Empfehlungen um. Bei Bedarf kannst du dich für weiterführende Beratungen und Laboranalysen gerne bei uns melden.",
        two_glasses_sec: "bis zu 0,5 l / 17 oz",
        six_glasses_sec: "0,5-1,5 l / 17-50 oz",
        seven_sec: "1,5-2,5 l / 50-85 oz",
        ten_glasses_sec: "mehr als 2,5 l / 85 oz",
        how_many_fruits_vegetables:
          "Wie viele Früchte oder Gemüse essen Sie pro Tag?",
        more_than_3: "Mehr als 3",
        how_often_consume_starch:
          "Wie häufig pro Tag konsumierst du Stärkelieferanten wie Kartoffeln, Reis, Brot, Pasta, Getreideflocken etc.?",
        how_many_servings_wholegrain:
          "Wie viele Portionen von den Stärkelieferanten bestehen aus Vollkornmehl resp. aus dem ganzen Korn?",
        how_many_portions_nuts_seeds_kernels_per_week:
          "Wie viele Portionen Nüsse, Samen und Kerne isst du pro Woche?",
        how_many_hours_sleep_per_night:
          "Wie viele Stunden schläfst du im Durchschnitt pro Nacht?",
        portion: "1 Portion = 1 Handvoll",
        select_work_percentage: "Wie viel Prozent arbeitest du?",
      },
    },
    fr: {
      translation: {
        Address: "Adresse fr",
      },
    },
  },
  keySeparator: false,
  interpolation: { escapeValue: false },
});

export default i18n;
