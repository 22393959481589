import React, { useState } from "react";
import "../../assets/sass/_characteristics.scss";
import Charact from "../../assets/images/charat.svg";
import Flower from "../../assets/images/flower-icon.svg";
import Cap from "../../assets/images/cap.svg";
import Knife from "../../assets/images/knife.svg";
import File from "../../assets/images/file.svg";
import Dumbell from "../../assets/images/dumbel.svg";
import Bag from "../../assets/images/bag.svg";
import { useTranslation } from "react-i18next";

const Characteristcs = () => {
  const [expandedBoxes, setExpandedBoxes] = useState({});
  const { t } = useTranslation();
  const CharacteristicsData = [
    {
      icon: Flower,
      title: t("personalized"),
      description: t("personalized_p"),
    },
    {
      icon: Cap,
      title: t("guidance"),
      description: t("guidance_p"),
    },
    {
      icon: Knife,
      title: t("food"),
      description: t("food_p"),
    },
    {
      icon: File,
      title: t("meal"),
      description: t("meal_p"),
    },
    {
      icon: Dumbell,
      title: t("lifestyle"),
      description: t("lifestyle_p"),
    },
    {
      icon: Bag,
      title: t("workshops"),
      description: t("workshops_p"),
    },
  ];

  const toggleReadMore = (index) => {
    setExpandedBoxes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="characteristics">
      <img className="img-fluid" src={Charact} alt="charat-logo" />
      <h3>{t("Features")}</h3>
      <p>{t("wellness")}</p>
      <div className="characteristics-row">
        {CharacteristicsData.map((data, index) => (
          <div key={index} className="characteristics-box">
            <div className="box-header">
              <img className="img-fluid" src={data.icon} alt="icon" />
              <h5>{data.title}</h5>
            </div>
            <p className={expandedBoxes[index] ? "expanded" : "collapsed"}>
              {data.description}
            </p>
            <button
              onClick={() => toggleReadMore(index)}
              className="read-more-btn"
            >
              {expandedBoxes[index] ? t("read_less") : t("read_more")}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Characteristcs;
