import React from "react";
import Charact from "../../assets/images/charat.svg";
import "../../assets/sass/_blogs.scss";
import Girl from "../../assets/images/girl.png";
import Girl1 from "../../assets/images/girl1.png";
import Girl3 from "../../assets/images/girl3.png";
import { useTranslation } from "react-i18next";

const Blogs = () => {
  const { t } = useTranslation();
  const BlogsDta = [
    {
      image: Girl,
      name: "Gewichtsverlust",
      title: "Die Vorteile der Flüssigkeitszufuhr zur Gewichtsreduktion",
      description:
        "Entdecken Sie, wie eine ausreichende Flüssigkeitszufuhr Ihre Abnehmziele unterstützen und die allgemeine Gesundheit verbessern kann.",
    },
    {
      image: Girl1,
      name: "Gewichtsverlust",
      title: "Die Vorteile der Flüssigkeitszufuhr zur Gewichtsreduktion",
      description:
        "Entdecken Sie, wie eine ausreichende Flüssigkeitszufuhr Ihre Abnehmziele unterstützen und die allgemeine Gesundheit verbessern kann.",
    },
    {
      image: Girl3,
      name: "Gewichtsverlust",
      title: "Die Vorteile der Flüssigkeitszufuhr zur Gewichtsreduktion",
      description:
        "Entdecken Sie, wie eine ausreichende Flüssigkeitszufuhr Ihre Abnehmziele unterstützen und die allgemeine Gesundheit verbessern kann.",
    },
  ];
  return (
    <div className="blogs">
      <img className="img-fluid" src={Charact} alt="charat-logo" />
      <h3>{t("blogs_h")}</h3>
      <p>{t("blogs_p")}</p>
      <div className="blogs-row">
        {BlogsDta.map((data, index) => (
          <div key={index} className="blogs-box">
            <img className="img-fluid" src={data.image} alt="girl" />
            <h5>{data.name}</h5>
            <h3>{data.title}</h3>
            <p>{data.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
